/*

  App.css
  -------
  Where the magic (supposedly) happens.

*/

@import url('https://fonts.googleapis.com/css?family=Courier+Prime&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');

body {
  background: #f8f9fa;
  color: #212529;
  font: .8rem 'Courier Prime', 'DotGothic16', monospace;
  margin: 0;
  padding: 3rem 20%;
}

.name, .header_title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #212529;
  text-align: center;
  @media only screen and (max-width: 767px) {
      font-size: 1.5rem; 
  }
}

.name_sub {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: #555555;
  text-align: center;
  @media only screen and (max-width: 767px) {
      font-size: .95rem; 
  }

}

.navcontainer, .textcontainer {
  padding: 2.5rem;
}

.navcontainer {
  display: flex;
  justify-content: space-around;
  border-bottom: 1.2px solid #e0e6e9;
}

.navbuttons *, .langico button {
  font-family: 'Courier Prime', 'DotGothic16', monospace;
  transition: color 0.2s ease-in-out;
  display: flex;
  align-items: center;
}

.navbuttons {
  justify-content: space-between;
  margin: 15px 0;
  font-size: 1rem;
  border-radius: 6px;
}

.navbuttons a, .langico {
  color: #212529;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.2s linear;
}

.navbuttons a {
  padding: 15px 25px;
  transition: background-color 0.2s linear, color 0.2s linear;
}

.navbuttons:hover {
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  transform: scale(1.05);
  transition: background-color 0.3s linear, color 0.4s linear, transform 0.2s linear;
}

.signature {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding-top: 1rem;
}

.signature img {
  width: 50%;
  height: auto;
}

.textcontainer {
  background: #f5f6f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
  width: 80%;
  margin: 0 auto;
  border-radius: 0.5rem;
}

.header_title, .header_company, .header_date, .header_desc, .header_desc2, .header_info, .header_info_exp, .block_magnify, .block_magnify2 {
  font-size: 1.1rem;
  line-height: 1.7;
  text-align: justify;
  padding: 1.5rem;
  border-radius: 25px;
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  color: #000;
}

.landing_date {
  font-size: 1.25rem;
  color: #626262;
  padding: 0 1.25rem 0.25rem 1.25rem;
  margin-bottom: .5rem;
}

.header_company, .header_company2 {
  font-weight: 700;
  font-size: 1.2rem;
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.header_date {
  font-weight: 700;
  font-size: 1rem;
  color:#626262;
  padding: 0 1.25rem 0.25rem 1.25rem;
  margin-bottom: .5rem;
}

.header_title {
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}

.block_magnify:hover, .block_magnify2:hover {
  background-color: #e9ecef;
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin: 1.5rem 0;
  cursor: url('./res/magnify.ico'), auto;
}

.block_magnify2:hover {
  cursor: pointer;
}

.pictureContainer, .picture {
  max-width: 100%;
  height: auto; 
  margin: 0 auto;
  margin-top: 1.5rem;
}

.picture img {
  max-width: 100%;  
  height: auto;
}

.pictureContainer {
  position: relative;
  padding: 0 12em;
}

.leftArrow, .rightArrow {
  position: absolute;
  top: 50%;
  color: #6c757d;
  font-size: 2.5rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.leftArrow {
  left: 35px;
}

.rightArrow {
  right: 35px;
}

.langico {
  position: fixed;
  top: 16px;
  right: 16px;
  color: #000;
  padding: 16px;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  
  button {
    justify-content: center;
    font-size: 1.1rem;
    cursor: pointer;
  }
}

/* footer styles */
footer {
  margin-top: 2.5rem;
  background-color: #f8f9fa;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e0e6e9;
}

.socials a {
  color: #212529;
  margin: 0 1.5rem; 
  text-decoration: none; 
}

.social_icon { 
  font-size: 1.5em;
  transition: color 0.2s ease-in-out;
}

.socials a:hover .social_icon {
  color: #007bff;
}

.writing_container {
  margin: 0 auto;
  width: 80%;
  padding: 1.2rem;
  border-radius: 0.5rem;
  background-color: #f3f3f3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  margin-bottom: 1.8rem;
}

.writing_container:hover {
  transform: scale(1.025);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.title_of_writing {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
}

.date_of_writing {
  font-size: .75rem;
  font-weight: 500;
  text-align: left;
  color: #a5a5a5;
  float: right;
}

.piece_of_writing {
  font-size: 1rem;
  line-height: 1.7;
  text-align: left;
  padding: 1.5rem;
  border-radius: 25px;
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  color: #212529;
}

@media only screen and (max-width: 1368px) {
  body {
    padding: 2rem 10%;
  }

  .name {
    font-size: 2rem;
  }

  .navbuttons {
    margin-top: 0rem;
    font-size: 1rem;
  }

  .navbuttons a {
    padding: 10px 20px;
  }

  .textcontainer {
    width: 85%;
  }
  
  .header_title, .header_company, .header_company2, .header_date, 
  .header_desc, .header_desc2, .header_info, 
  .header_info_exp, .block_magnify, .block_magnify2 {
    font-size: 1.1rem;
    padding: 1rem;
    text-align: left;
  }

  .header_title {
    font-size: 2rem;
    text-align: center;
  }

  .pictureContainer {
    padding: 0 6em;
  }
  
  .langico {
    top: 8px;
    left: 8px;
    padding: 8px;
  }

  .langico button {
    font-size: .8rem;
  }

  .signature img {
    width: 80%;
    height: auto;
  }

  .writing_container {
    width: 80%;
    margin-bottom: 1.5rem;
  }

  .title_of_writing {
    font-size: 1.2rem;
  }

  .piece_of_writing {
    font-size: 0.7rem;
  }

  .date_of_writing {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 767px) {
  body {
    padding: .5rem 5%;
  }

  .name {
    margin-top: 1.2rem;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .navcontainer {
    align-items: center;
  }

  .navbuttons {
    font-size: 0.5rem;
  }

  .navbuttons a {
    padding: 5px 10px;
  }

  .header_title, .header_company, .header_company2, .header_date, 
  .header_desc, .header_desc2, .header_info, 
  .header_info_exp, .block_magnify, .block_magnify2 {
    font-size: 0.7rem;
    padding: 0.8rem;
    text-align: left;
  }

  .header_title {
    font-size: 1.2rem;
    text-align: center;
  }

  .pictureContainer {
    padding: 0 4em;
  }

  .langico {
    top: 5px;
    left: 5px;
    padding: 0px;
  }

  .langico button {
    font-size: .4rem;
  }

  .signature {
    padding-top: 0.5rem;
  }

  .textcontainer {
    padding: 0 1em 1em;
  }

  .signature img {
    width: 80%;
    height: auto;
  }

  .pictureContainer {
    padding: 0 5.25em;
  }

  .picture img {
    max-height: 100%;
    width: auto;
  }

  .leftArrow, .rightArrow {
    font-size: 1rem;
  }

  .writing_container {
    width: 80%;
    margin-bottom: 1rem;
  }

  .title_of_writing {
    font-size: 1rem;
  }

  .piece_of_writing {
    font-size: 0.6rem;
  }

  .date_of_writing {
    font-size: 0.5rem;
  }
}

